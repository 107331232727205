
/**
 * Copies the provided text to the user's clipboard.
 *
 * @param {string} text - The text to copy to the clipboard.
 * @returns {boolean} - Returns true if the copying was successful, and false otherwise.
 */
const copyToClipboard = async (text) => {
    // If the Clipboard API is available, use it
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            return true;
        } catch (err) {
            console.error('Failed to copy text using Clipboard API: ', err);
        }
    }

    // Fallback to using a temporary textarea
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let successful = false;
    try {
        successful = document.execCommand('copy');
    } catch (err) {
        console.error('Failed to copy text using execCommand: ', err);
    }

    document.body.removeChild(textArea);
    return successful;
};

export default copyToClipboard;
