import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  BLACK,
  COOL_GREY,
  COOL_GREY50,
  HEADER_HEIGHT,
  PRIMARY_FONT,
  RED,
  RICH_BLACK,
  WHITE,
} from "../../utils/constants";
import footerBackground from "../../assets/footer-bg.png";
import contentBackground from "../../assets/portal-bg.jpg";

export const Container = styled.section`
  background-color: ${BLACK};
`;

export const SocialsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${WHITE};
  margin-bottom: 8rem;

  @media ${MediaQuery.isTablet} {
    margin-bottom: 4rem;
  }

  @media ${MediaQuery.isMobile} {
    margin-bottom: 4rem;
  }
`;

export const LogoContainer = styled.figure`
  margin-bottom: 2rem;
`;

export const Socials = styled.div`
  display: flex;
  margin-bottom: 2rem;

  a {
    color: ${WHITE};
  }
`;

export const SocialContainer = styled.figure`
  height: 6rem;
  width: 6rem;
  padding: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 1rem;

  a,
  svg {
    color: ${COOL_GREY};
    height: 100%;
    width: 100%;
    transition: 0.2s ease-in-out;

    &:hover {
      color: ${WHITE};
    }
  }

  @media ${MediaQuery.isTablet} {
    margin: 0;
    padding: 1.2rem;
  }

  @media ${MediaQuery.isMobile} {
    margin: 0;
    padding: 1.2rem;
  }
`;

export const Links = styled.div`
  display: flex;

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: ${PRIMARY_FONT};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 2rem;
  color: ${COOL_GREY};
  transition: 0.2s ease-in-out;

  &:hover {
    color: ${WHITE};
  }

  @media ${MediaQuery.isTablet} {
    margin: 1rem;
  }

  @media ${MediaQuery.isMobile} {
    margin: 1rem 0;
  }
`;

export const Footer = styled.footer`
  background-image: url(${footerBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  width: 100%;
  padding: 7rem 2rem 4rem;
`;

export const Header = styled.header`
  height: ${HEADER_HEIGHT};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 124rem;

  @media ${MediaQuery.isTablet} {
    margin-bottom: 4rem;
    padding: 0 2rem;
  }

  @media ${MediaQuery.isMobile} {
    margin-bottom: 4rem;
    padding: 0 2rem;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
`;

export const ContainerLink = styled(Link)`
  background-color: #04080f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.5rem 0 0.5rem 2rem;
  border-radius: 8rem;
  padding: 0.4rem 1rem;
  color: ${WHITE};

  img {
    margin-left: 1rem;
  }

  @media ${MediaQuery.isTablet} {
    margin: 0;

    img {
      margin: 0;
    }
  }

  @media ${MediaQuery.isMobile} {
    margin: 0;

    img {
      margin: 0;
    }
  }
`;

export const Text = styled.p`
  margin-left: 2rem;
  color: ${COOL_GREY50};
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100rem;

  @media ${MediaQuery.isTablet} {
    flex-direction: column-reverse;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.131);
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column-reverse;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.131);
  }
`;

export const FooterText = styled.p`
  color: ${COOL_GREY50};

  @media ${MediaQuery.isTablet} {
    margin-top: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    margin-top: 2rem;
  }
`;

export const FooterLink = styled(Link)`
  color: ${COOL_GREY};
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(100vh - ${HEADER_HEIGHT});
`;

export const BackgroundContainer = styled.div`
  background-image: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage})`
      : `url(${contentBackground})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Hamburger = styled.div`
  background-color: ${RICH_BLACK};
  border-radius: 100%;
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem 1rem;

  div {
    height: 0.2rem;
    width: 100%;
    background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  }
`;

export const MobileNavigation = styled.div`
  position: absolute;
  left: 0;
  top: ${HEADER_HEIGHT};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: ${RICH_BLACK};
  z-index: 10;
`;

export const IconContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const IconButton = styled.div`
  border-radius: 5rem;
  background-color: ${RED};
  padding: 1rem 2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media ${MediaQuery.isMobile} {
    width: 100%;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
