import React from "react";
import { Container } from "./Button.Styled";
import { useNavigate } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

const Button = ({
  onClick,
  name,
  value,
  children,
  disabled,
  type,
  style,
  secondary,
  icon,
  gradient,
  textColor,
}) => {
  const navigate = useNavigate();

  function redirect(path) {
    navigate(path);
  }

  return (
    <Container
      disabled={disabled ? disabled : undefined}
      isDisabled={disabled ? disabled : undefined}
      type={type ? type : "button"}
      name={name ? name : ""}
      value={value ? value : ""}
      onClick={typeof onClick === "string" ? () => redirect(onClick) : onClick}
      style={style ? style : undefined}
      secondary={secondary}
      gradient={gradient}
      textColor={textColor}
    >
      <p style={{ flexWrap: "wrap" }}>{children}</p>
      {icon ? icon : <BsArrowUpRight />}
    </Container>
  );
};

export default Button;
