import { toast } from "react-toastify";
import { handleMessages } from "./snacks";

// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError(navigate, flowType, resetFlow) {
  return async (err) => {
    if (!err.response) {
      resetFlow(undefined);
      return;
    }

    switch (err?.response?.data.error?.id) {
      case "session_aal2_required":
        // 2FA is enabled and enforced, but user did not perform 2fa yet!
        window.location.href = err.response?.data.redirect_browser_to;
        return;
      case "session_already_available":
        // User is already signed in, let's redirect them home!
        await navigate("/account");
        return;
      case "session_inactive":
        await navigate("/login");
        return;
      case "session_refresh_required":
        // We need to re-authenticate to perform this action
        const redirectUrl = err.response?.data.redirect_browser_to;
        toast.error("You are being redirected to re-authentication.", {
          onClose: () => {
            window.location.href = redirectUrl;
          },
        });
        return;
      case "self_service_flow_return_to_forbidden":
        // The flow expired, let's request a new one.
        toast.error("The return_to address is not allowed.");
        resetFlow(undefined);
        await navigate("/" + flowType);
        return;
      case "self_service_flow_expired":
        // The flow expired, let's request a new one.
        toast.error(
          "Your interaction expired, please fill out the form again."
        );
        resetFlow(undefined);
        await navigate("/" + flowType);
        return;
      case "security_csrf_violation":
        // A CSRF violation occurred. Best to just refresh the flow!
        toast.error(
          "A security violation was detected, please fill out the form again."
        );
        resetFlow(undefined);
        await navigate("/" + flowType);
        return;
      case "security_identity_mismatch":
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined);
        await navigate("/" + flowType);
        return;
      case "browser_location_change_required":
        // Ory Kratos asked us to point the user to this URL.
        window.location.href = err.response.data.redirect_browser_to;
        return;
      default:
        navigate("/");
    }

    switch (err.response?.status) {
      case 410:
        // The flow expired, let's request a new one.
        resetFlow(undefined);
        await navigate("/" + flowType);
        return;
      default:
        console.log("Unknown status, redirecting to /account", err.response);
        navigate("/account");
    }

    handleMessages(err.response?.data);

    // We are not able to handle the error? Return it.
    return Promise.reject(err);
  };
}

// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError;
