import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { kratosClient } from "../api/kratos";
import { handleFlowError } from "./errors";

// Returns a function which will log the user out
export function LogoutLink(deps) {
  const [logoutToken, setLogoutToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/account") {
      kratosClient
        .createBrowserLogoutFlow()
        .then(({ data }) => {
          setLogoutToken(data.logout_token);
        })
        .catch((err) => {
          handleFlowError(navigate, "logout", setLogoutToken)(err);

          switch (err.response?.status) {
            case 401:
              // do nothing, the user is not logged in
              return;
          }

          // Something else happened!
          return Promise.reject(err);
        });
    }
  }, [...deps, location.pathname]);

  return () => {
    if (logoutToken) {
      kratosClient
        .updateLogoutFlow({ token: logoutToken })
        .then(() => navigate("/login"));
    }
  };
}
