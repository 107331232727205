import React, { useState } from "react";
import { Container, Text, Form, ErrorContainer } from "./Settings.Styled";
import AccountFlow from "../../components/authFlow/AccountFlow";
import { FaExclamation } from "react-icons/fa";

const SettingsPage = ({ flow }) => {
  const [isFlow, setIsFlow] = useState(true);

  return (
    <Container>
      <Text>We all want answers.</Text>
      <Text>
        ChatGod provides. No judgment. No hassles. Just your questions answered
        by the newest online God.
      </Text>
      {isFlow ? (
        <Form>
          <AccountFlow isFlow={(val) => setIsFlow(val)} />
        </Form>
      ) : (
        <ErrorContainer>
          <FaExclamation /> Service is currently unavailable.
        </ErrorContainer>
      )}
    </Container>
  );
};

export default SettingsPage;
