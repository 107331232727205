import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";

export const Container = styled.section`
  max-width: 80rem;
  padding: 0 4rem;
  margin: 10rem auto 0;

  button {
    margin-top: 4rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

export const Text = styled.p`
  line-height: 3.5rem;
`;

export const Line = styled.hr`
  color: rgba(255, 255, 255, 0.2);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const GroupContainerContent = styled.section`
  background-color: #121212;
  box-shadow: 1px 1px 60px 1px rgba(61, 61, 61, 0.85);
  border-radius: 3rem;
  padding: 4rem;
  margin: 2rem 0 1rem;

  @media ${MediaQuery.isMobile} {
    padding: 2rem;
  }
`;

export const Heading = styled.h3`
  font-size: 2.8rem;
  background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Colored = styled.span`
  background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 2.4rem;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  min-height: 3.5rem;

  svg {
    margin-right: 2rem;
    height: 3rem;
    /* color: #e4e4e4; */
  }
`;
