import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  SocialsContainer,
  Header,
  Socials,
  SocialContainer,
  Links,
  Link,
  LogoContainer,
  Footer,
  Navigation,
  ContainerLink,
  Text,
  FooterText,
  FooterContent,
  FooterLink,
  Content,
  BackgroundContainer,
  Hamburger,
  MobileNavigation,
  IconContainer,
  IconButton,
  LogoutContainer,
} from "./Layout.Styled";
import {
  BsInstagram,
  BsTwitter,
  BsFacebook,
  BsLinkedin,
  BsTelegram,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { hotjar } from "react-hotjar";
import { MediaQuery, useDeviceType } from "../../hooks/useDeviceType";
import { MdLogout } from "react-icons/md";
import { LogoutLink } from "../../pkg/hooks";
import { kratosClient } from "../../api/kratos";

import logo from "../../assets/logo.svg";
import smallLogo from "../../assets/logo-small.svg";
import telegram from "../../assets/telegram.svg";
import whatsapp from "../../assets/whatsapp.svg";
import accountBackground from "../../assets/account-bg.png";
import smallAccountBackground from "../../assets/small-account-bg.jpg";
import background from "../../assets/portal-bg.jpg";
import { WHITE } from "../../utils/constants";

const Layout = ({ children, isSettings }) => {
  const [year, setYear] = useState();
  const [backgroundImage, setBackgroundImage] = useState("");
  const [showNavigation, setShowNavigation] = useState(false);
  const [isSession, setIsSession] = useState(false);

  const isMobile = useDeviceType(MediaQuery.isMobile);
  const isDesktop = useDeviceType(MediaQuery.isDesktop);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const aal = queryParams.get("aal");
  const refresh = queryParams.get("refresh");

  const onLogout = LogoutLink([aal, refresh]);

  useEffect(() => {
    kratosClient
      .toSession()
      .then((data) => {
        setIsSession(true);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setIsSession(false);
      });
  }, [location.pathname]);

  useEffect(() => {
    const date = new Date();

    setYear(date.getFullYear());
  }, []);

  useEffect(() => {
    hotjar.stateChange(location.pathname);

    setShowNavigation(false);

    if (location.pathname === "/account") {
      if (!isSettings) {
        setBackgroundImage(accountBackground);
      } else {
        setBackgroundImage(smallAccountBackground);
      }
    } else {
      setBackgroundImage(background);
    }
  }, [location.pathname, isSettings]);

  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowNavigation(false);
  };

  return (
    <Container>
      <BackgroundContainer backgroundImage={backgroundImage}>
        {!isMobile ? (
          <Header>
            <Link href="https://chatgod.ai">
              <img src={logo} alt="logo" />
            </Link>
            {/* <Navigation>
              <Link
                href="https://chatgod.ai/#Features"
                rel="noreferrer noopnener"
                target="_blank"
              >
                Product
              </Link>{" "}
              <Link
                href="https://chatgod.ai/pricing/"
                rel="noreferrer noopnener"
                target="_blank"
              >
                Pricing
              </Link>{" "}
              <Link
                href="https://chatgod.ai/#Token"
                rel="noreferrer noopnener"
                target="_blank"
              >
                Token
              </Link>
              <Link
                href="https://chatgod.ai/#Docs"
                rel="noreferrer noopnener"
                target="_blank"
              >
                Docs
              </Link>
              |<Text>Try on:</Text>
              <ContainerLink
                href="http://t.me/OfficialChatGodAIBot"
                rel="noreferrer noopnener"
                target="_blank"
              >
                {isDesktop ? "Telegram" : null}
                <img src={telegram} alt="telegram" />
              </ContainerLink>
              <ContainerLink
                href="http://wa.me/+17192594037?text=/start"
                rel="noreferrer noopnener"
                target="_blank"
              >
                {isDesktop ? " WhatsApp" : null}
                <img src={whatsapp} alt="whatsapp" />
              </ContainerLink>
             
            </Navigation>{" "} */}
            {isSession ? (
              <IconButton onClick={onLogout}>
                <IconContainer>
                  <MdLogout color={WHITE.hex()} />
                </IconContainer>
              </IconButton>
            ) : null}
          </Header>
        ) : (
          <Header>
            <Link href="/">
              <img src={smallLogo} alt="logo" />
            </Link>
            {/* <Navigation>
              <Text>Try on:</Text>
              <ContainerLink
                href="http://t.me/OfficialChatGodAIBot"
                rel="noreferrer noopnener"
                target="_blank"
              >
                <img src={telegram} alt="telegram" />
              </ContainerLink>
              <ContainerLink
                href="http://wa.me/+17192594037?text=/start"
                rel="noreferrer noopnener"
                target="_blank"
              >
                <img src={whatsapp} alt="whatsapp" />
              </ContainerLink>
            </Navigation>
            <Hamburger onClick={() => setShowNavigation(true)}>
              <div />
              <div />
              <div style={{ width: "80%" }} />
            </Hamburger>
            {showNavigation ? (
              <MobileNavigation ref={node}>
                <Link
                  href="https://chatgod.ai/#Features"
                  rel="noreferrer noopnener"
                  target="_blank"
                >
                  Product
                </Link>{" "}
                <Link
                  href="https://chatgod.ai/pricing/"
                  rel="noreferrer noopnener"
                  target="_blank"
                >
                  Pricing
                </Link>{" "}
                <Link
                  href="https://chatgod.ai/#Token"
                  rel="noreferrer noopnener"
                  target="_blank"
                >
                  Token
                </Link>
                <Link
                  href="https://chatgod.ai/#Docs"
                  rel="noreferrer noopnener"
                  target="_blank"
                >
                  Docs
                </Link>
                {isSession ? (
                  <IconButton onClick={onLogout}>
                    <IconContainer>
                      <MdLogout color={WHITE.hex()} />
                    </IconContainer>
                  </IconButton>
                ) : null}
              </MobileNavigation>
            ) : null} */}
            <LogoutContainer>
              {isSession ? (
                <IconButton onClick={onLogout}>
                  <IconContainer>
                    <MdLogout color={WHITE.hex()} />
                  </IconContainer>
                </IconButton>
              ) : null}
            </LogoutContainer>
          </Header>
        )}
        <Content>{children}</Content>
      </BackgroundContainer>
      <Footer>
        <SocialsContainer>
          <LogoContainer>
            <img src={logo} alt="logo" />
          </LogoContainer>

          <Socials>
            <a
              href="https://www.instagram.com/chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <BsInstagram />
              </SocialContainer>{" "}
            </a>{" "}
            <a
              href="https://twitter.com/Chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <BsTwitter />
              </SocialContainer>
            </a>{" "}
            <a
              href="https://www.facebook.com/chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <BsFacebook />
              </SocialContainer>{" "}
            </a>
            <a
              href="https://www.facebook.com/chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <FaTiktok />
              </SocialContainer>{" "}
            </a>
            <a
              href="https://www.facebook.com/chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <BsLinkedin />
              </SocialContainer>{" "}
            </a>
            <a
              href="https://www.facebook.com/chatgodai"
              target="_blank"
              rel="noreferrer"
            >
              <SocialContainer>
                <BsTelegram />
              </SocialContainer>{" "}
            </a>
          </Socials>
        </SocialsContainer>
        <FooterContent>
          <FooterText>© {year} ChatGod | All Rights Reserved. </FooterText>
          <Links>
            <FooterLink href="https://chatgod.ai/privacy-policy/">
              Privacy Policy
            </FooterLink>
            <FooterLink href="https://chatgod.ai/terms-of-service/">
              Terms of Service
            </FooterLink>
            <FooterLink href="https://chatgod.ai/cookie-policy/">
              Cookies Policy
            </FooterLink>
          </Links>
        </FooterContent>
      </Footer>
    </Container>
  );
};

export default Layout;
