import React, { useEffect, useState } from "react";
import { kratosClient } from "../../api/kratos";
import Button from "../../components/button/Button";
import {
  Form,
  Input,
  Line,
  OIDCButton,
  ForgotPwdContainer,
  Separator,
  InputContainer,
} from "./AuthFlow.Styled";
import { LoginFlow, RegistrationFlow, RecoveryFlow } from "@ory/client";
import { handleFlowError } from "../../pkg/errors";
import { handleMessages } from "../../pkg/snacks";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { LIGHTER_BLACK } from "../../utils/constants";
import { getObjectHash } from "../../utils/hash";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { capitalizeFirstLetter } from "../../utils/helpers";

const AuthFlow = ({ flow, isFlow }) => {
  const [justRegistered, setJustRegistered] = useState();
  const [flowType, setFlowType] = useState();
  const [isVerified, setIsVerified] = useState();
  const [flowData, setFlowData] = useState(
    flow === "login"
      ? LoginFlow
      : flow === "recovery"
      ? RecoveryFlow
      : RegistrationFlow
  );

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const aal = queryParams.get("aal");
  const returnTo = queryParams.get("return_to");
  const flowId = queryParams.get("flow");
  const email = queryParams.get("email");
  const phone = queryParams.get("phone");
  const referralCode = Cookies.get("referralCode");

  async function fetchData() {
    if (location.state) {
      let { redirected, verified } = location.state;

      setJustRegistered(redirected);
      setIsVerified(verified);
    }

    if (!flowData || flowType != flow) {
      setFlowType(flow);

      if (!flowId) {
        let url = new URL(window.location.href);

        if (flow === "login") {
          kratosClient
            .createBrowserLoginFlow({
              refresh: false,
              aal: aal ? String(aal) : undefined,
              returnTo: returnTo ? String(returnTo) : undefined,
            })
            .then(({ data }) => {
              url.searchParams.set("flow", data.id);
              navigate(url.pathname + url.search + url.hash, {
                replace: true,
              });
              setFlowData(data);
            })
            .catch((err) => {
              handleFlowError(navigate, "login", setFlowData)(err);

              isFlow(flowData);
            });
        } else if (flow === "register") {
          kratosClient
            .createBrowserRegistrationFlow({
              refresh: false,
              aal: aal ? String(aal) : undefined,
              returnTo: returnTo ? String(returnTo) : undefined,
            })
            .then(({ data }) => {
              url.searchParams.set("flow", data.id);
              navigate(url.pathname + url.search + url.hash, {
                replace: true,
              });
              setFlowData(data);
            })
            .catch((err) => {
              handleFlowError(navigate, "registration", setFlowData)(err);

              isFlow(flowData);
            });
        } else if (flow === "recovery") {
          kratosClient
            .createBrowserRecoveryFlow()
            .then(({ data }) => {
              url.searchParams.set("flow", data.id);
              navigate(url.pathname + url.search + url.hash, {
                replace: true,
              });
              setFlowData(data);
            })
            .catch((err) => {
              handleFlowError(navigate, "recovery", setFlowData)(err);

              isFlow(flowData);
            });
        } else {
          isFlow(undefined);
        }
      } else {
        if (flow === "login") {
          kratosClient
            .getLoginFlow({ id: flowId })
            .then(({ data }) => {
              setFlowData(data);
            })
            .catch((err) => {
              handleFlowError(navigate, "login", setFlowData)(err);
            });
        } else if (flow === "register") {
          kratosClient
            .getRegistrationFlow({ id: flowId })
            .then(({ data }) => {
              handleMessages(data);
              setFlowData(data);
            })
            .catch(handleFlowError(navigate, "register", setFlowData));
        } else if (flow === "recovery") {
          kratosClient
            .getRecoveryFlow({ id: flowId })
            .then(({ data }) => {
              setFlowData(data);
            })
            .catch(handleFlowError(navigate, "recovery", setFlowData));
        }
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const btn = e.nativeEvent.submitter;

    const formData = {};
    for (let i = 0; i < e.target.elements.length; i++) {
      const element = e.target.elements[i];

      if (
        flowData.state !== "choose_method" &&
        element.name === "email" &&
        btn.value === "code"
      ) {
        continue;
      }

      formData[element.name] = element.value;
    }

    const flowSubmit =
      flow === "recovery"
        ? kratosClient
            .updateRecoveryFlow({
              flow: String(flowData?.id),
              updateRecoveryFlowBody: formData,
            })
            .then(({ data }) => {
              setFlowData(data);
              handleMessages(data);
            })
        : flow === "login"
        ? kratosClient
            .updateLoginFlow({
              flow: String(flowData?.id),
              updateLoginFlowBody: formData,
            })
            .then(({ data }) => {
              if (data.session) {
                navigate("/account");
                return;
              }

              setFlowData(data);
              handleMessages(data);
            })
        : flow === "register"
        ? kratosClient
            .updateRegistrationFlow({
              flow: String(flowData?.id),
              updateRegistrationFlowBody: formData,
            })
            .then(({ data }) => {
              if (data.identity?.state === "active") {
                navigate("/login", { state: { redirected: true } });
                return;
              }
              handleMessages(data);
            })
        : null;

    if (flowSubmit) {
      flowSubmit
        .then((data) => {
          handleMessages(data);
        })
        .catch((err) => {
          if (err.response.data.error) {
            if (
              err.response.data.error.id &&
              err.response.data.redirect_browser_to
            ) {
              window.location.replace(err.response.data.redirect_browser_to);
            }
          }

          handleMessages(err.response.data);
        });
    }
  }

  function getOIDCInputs() {
    let tempOIDCInputs = [];

    if (flowData && flowData.ui && flowData.ui.nodes) {
      const formData = flowData.ui;

      for (let i = 0; i < formData.nodes.length; i++) {
        if (formData.nodes[i].attributes.type === "hidden") {
          tempOIDCInputs.push(<Input {...formData?.nodes[i].attributes} />);
        } else if (
          formData.nodes[i].meta.label.text === "Sign in with google" ||
          formData.nodes[i].meta.label.text === "Sign up with google"
        ) {
          tempOIDCInputs.push(
            <OIDCButton {...formData.nodes[i].attributes}>
              <FcGoogle />
              Sign in with{" "}
              {capitalizeFirstLetter(formData.nodes[i].attributes.value)}
            </OIDCButton>
          );
        }
      }
    }

    return tempOIDCInputs;
  }

  function getDefaultInputs() {
    let tempDefaultInputs = [];
    let passwordAndSubmit = [];
    let hasBackButton = false;

    if (flowData && flowData.ui && flowData.ui.nodes) {
      const formData = flowData.ui;

      for (let i = 0; i < formData.nodes.length; i++) {
        const node = formData.nodes[i];
        const { value, ...rest } = node.attributes;
        const nodeAttr = value !== "" ? { value, ...rest } : { ...rest };

        if (
          node.meta.label?.text !== "Sign in with google" &&
          node.meta.label?.text !== "Sign up with google"
        ) {
          if (node.attributes.type === "submit") {
            if (flow === "login") {
              passwordAndSubmit.push(
                <ForgotPwdContainer style={{ marginTop: "1rem" }}>
                  <Link to="/recovery" style={{ textAlign: "center" }}>
                    Forgot password?
                  </Link>
                </ForgotPwdContainer>
              );
            } else if (flow === "recovery" && !hasBackButton) {
              passwordAndSubmit.push(
                <ForgotPwdContainer>
                  <Link to="/login" style={{ textAlign: "center" }}>
                    Back to Login
                  </Link>
                </ForgotPwdContainer>
              );

              hasBackButton = true;
            }
            passwordAndSubmit.push(
              node.meta.label.text === "Resend code" ? (
                <Button
                  style={{ marginTop: 20, backgroundColor: LIGHTER_BLACK }}
                  {...nodeAttr}
                >
                  {node.meta.label.text}
                </Button>
              ) : (
                <Button style={{ marginTop: 20 }} gradient {...nodeAttr}>
                  {node.meta.label.text}
                </Button>
              )
            );
          } else if (node.attributes.type === "hidden") {
            tempDefaultInputs.push(<Input {...nodeAttr} />);
          } else {
            if (node.meta?.label?.text !== "Password") {
              if (node.meta?.label?.text === "Phone") {
                tempDefaultInputs.push(
                  <InputContainer>
                    <p>*</p>
                    <Input
                      placeholder={node.meta.label.text + " (+1)"}
                      {...rest}
                      {...(phone ? { defaultValue: phone } : {})}
                      {...(value ? { defaultValue: value } : {})}
                    />
                  </InputContainer>
                );
              } else if (
                node.meta?.label?.text === "E-Mail" &&
                node.group === "oidc"
              ) {
                tempDefaultInputs.push(
                  <InputContainer>
                    <Input
                      placeholder={node.meta.label.text}
                      {...nodeAttr}
                      {...(value ? { readOnly: true } : {})}
                    />
                  </InputContainer>
                );
              } else if (
                node.meta?.label?.text === "E-Mail"
              ) {
                tempDefaultInputs.push(
                  <InputContainer>
                    <p>*</p>
                    <Input
                      placeholder={node.meta.label.text}
                      {...nodeAttr}
                      {...(email ? { defaultValue: email } : {})}
                    />
                  </InputContainer>
                );
              } else if (node.meta.label.text === "Referral Code / Email") {
                passwordAndSubmit.push(
                  <InputContainer>
                    <Input
                      placeholder={
                        node.meta.label.text === "ID"
                          ? "E-Mail or Phone number"
                          : node.meta.label.text
                      }
                      {...nodeAttr}
                      {...(value ? { readOnly: true } : {})}
                      defaultValue={
                        node.meta.label.text === "Referral Code / Email" &&
                        referralCode
                          ? referralCode
                          : ""
                      }
                    />
                  </InputContainer>
                );
              } else {
                tempDefaultInputs.push(
                  <InputContainer>
                    <p>*</p>
                    <Input
                      placeholder={
                        node.meta.label.text === "ID"
                          ? "E-Mail or Phone number"
                          : // TODO: all the * characters should be based on the required attr
                            node.meta.label.text
                      }
                      {...nodeAttr}
                      {...(value ? { readOnly: true } : {})}
                    />
                  </InputContainer>
                );
              }
            } else {
              passwordAndSubmit.push(
                <InputContainer>
                  <p>*</p>
                  <Input placeholder={node.meta.label.text} {...nodeAttr} />
                </InputContainer>
              );
            }
          }
        }
      }
    }

    return tempDefaultInputs.concat(passwordAndSubmit);
  }

  useEffect(() => {
    fetchData();
  }, [flow, flowId, location.state, flowData]);

  return (
    <>
      {flowData ? (
        <>
          {flow === "recovery" ? (
            flowData.state === "sent_email" ? (
              <p>Please enter the Recovery-Code you received in your E-Mail</p>
            ) : (
              <p>Please enter your E-Mail to receive a recovery code</p>
            )
          ) : null}
          <Form onSubmit={(e) => handleSubmit(e)}>
            {justRegistered
              ? (() => {
                  toast.warn(
                    "You need to verify your email before you can login. We have sent you a verification link."
                  );
                  toast.warn(
                    "Please check your spam folder for the verification email."
                  );
                  setJustRegistered(false);
                })()
              : null}
            {isVerified
              ? (() => {
                  toast.success(
                    "Congratulations! Your email is verified. You can login now."
                  );
                  setIsVerified(false);
                })()
              : null}
            {getDefaultInputs().map((input, i) => (
              // TODO: hash data to determine if it has changed. It is probably
              // more efficient to create a seperate state for this.
              // Computing the hash every render might be abit intensive
              <div key={i + getObjectHash(flowData)}>{input}</div>
            ))}
          </Form>
          {flow !== "recovery" ? (
            <Separator>
              <Line />
              <p>OR</p>
              <Line />
            </Separator>
          ) : null}
          <Form onSubmit={(e) => handleSubmit(e)}>
            {getOIDCInputs().map((input, i) => (
              <div key={i + getObjectHash(flowData)}>{input}</div>
            ))}
          </Form>
        </>
      ) : null}
    </>
  );
};

export default AuthFlow;
