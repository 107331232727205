import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import { kratosClient } from "../../api/kratos";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  TextContainer,
  Text,
  Colored,
  SummaryContainer,
  GroupSocials,
  SpecialText,
  Link,
  Table,
  TableData,
  TableHeading,
  TableRow,
  PayoutForm,
  Select,
  CountInput,
  PayoutButton,
  Pagination,
  WalletInput,
  PayoutGroup,
  ModifiedInput,
  MaxContainer,
  InfoPanel,
  ModalContainer,
  ModalContent,
  FormContent,
  LoaderContainer,
  Title,
  Subheading,
  GroupContainerContent,
  ContainerLink,
  BoldText,
  ProfileHeader,
  AlignContainer,
  GroupContainerLeft,
  GroupContainerRight,
  ReferralsContainer,
  GroupContent,
  List,
  NoReferralsContainer,
  Heading,
  EditForm,
  TableRowHeading,
  Separator,
  ButtonsContainer,
  GroupContainerGrouping,
  GroupLinks,
  AccountInfoRow,
  BoldTextColored,
} from "./AccountPage.Styled";

import axios from "../../api/axios";
import AccountFlow from "../../components/authFlow/AccountFlow";
import { FaArrowLeft, FaInfoCircle, FaMoneyBillWave } from "react-icons/fa";
import { toast } from "react-toastify";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import RingLoader from "react-spinners/RingLoader";
import Web3 from "web3";
import { FiEdit3 } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsTelegram, BsShieldFill } from "react-icons/bs";

import whatsapp from "../../assets/whatsapp.svg";
import telegram from "../../assets/telegram.svg";
import god from "../../assets/god.png";
import { MediaQuery, useDeviceType } from "../../hooks/useDeviceType";

const userAPI = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_PATH}/user/info`;

const payoutAPI = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_PATH}/user/request_payout`;

const createAffiliateAPI = `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_PATH}/user/create_affiliate`;

const mockupReferrals = [
  {
    InviteeEmail: "example@gmail.com",
    IsInviteeSubscribed: true,
  },
  {
    InviteeEmail: "example@gmail.com",
    IsInviteeSubscribed: true,
  },
];

const userObject = {
  Internal: {
    Number: "",
    StripeID: "",
    WhatsAppName: "",
    Telegram: {
      Username: "",
    },
    Email: "",
    FreetierMessages: 0,
    Verified: false,
  },
  Referrals: [],
  PayableReferrals: 0,
  StripeAffiliateAccount: null,
  StripeAffiliateLoginURL: null,
  StripeCustomer: null,
};

const AccountPage = ({ isSettings }) => {
  const [user, setUser] = useState(userObject);
  const [kratosUser, setKratosUser] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [countPayouts, setCountPayouts] = useState(0);
  const [itemLimit, setItemLimit] = useState(5);
  const [payoutMethod, setPayoutMethod] = useState("stripe");
  const [walletAddress, setWalletAddress] = useState("");
  const [isInfo, setIsInfo] = useState(false);
  const [payoutResp, setPayoutResp] = useState({});

  const isMobile = useDeviceType(MediaQuery.isMobile);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    kratosClient
      .toSession()
      .then(({ data }) => {
        setKratosUser(data);
      })
      .catch(() => {
        navigate("/login");
      });
  }, []);

  async function createAffiliateAccount() {
    if (user.StripeAffiliateAccount) {
      toast.info("Hang tight, we are fetching your account details!");
    } else {
      toast.info("Hang tight, we are creating your account!");
    }
    axios
      .get(`${createAffiliateAPI}?country=${navigator.language.split("-")[1]}`)
      .then(({ data }) => {
        console.log(data);

        window.open(data.url, "_blank");
        setPayoutResp({ foo: "bar" });
      })
      .catch(({ response }) => {
        toast.error(`Failed to create affiliate account: ${response.message}`);
      });
  }

  async function affiliateLoginOrCreate() {
    if (user.StripeAffiliateLoginURL) {
      window.open(user.StripeAffiliateLoginURL?.url, "_blank");
      return;
    }

    createAffiliateAccount();
  }

  function isPayoutDisabled() {
    return (
      (payoutMethod === "stripe" && countPayouts < 3) ||
      (payoutMethod === "crypto" && countPayouts < 1)
    );
  }

  async function handlePayout(e) {
    e.preventDefault();

    if (isPayoutDisabled()) {
      return;
    }

    if (payoutMethod === "crypto" && !Web3.utils.isAddress(walletAddress)) {
      toast.error("Invalid crypto BEP20 address");
      return;
    }

    if (payoutMethod === "crypto" || user.StripeAffiliateAccount) {
      axios
        .post(payoutAPI, {
          count: Number(countPayouts),
          method: payoutMethod,
          wallet: walletAddress,
        })
        .then(({ data }) => {
          console.log(data);
          setPayoutResp(data);
          toast.success("Your payout is on its way!");
        })
        .catch(({ response }) => {
          console.log(response.data);
          toast.error(`Referral payout failed: ${response.data.message}`);
        });
    } else {
      createAffiliateAccount();
    }
  }

  async function fetchData() {
    if (kratosUser) {
      try {
        const response = await axios.get("/user/info", {
          withCredentials: true,
        });

        console.log(response.data);

        setUser(response.data);
        isSettings(false);
      } catch (err) {
        console.log(err);
        isSettings(true);
      }
    }
  }

  function maxPayout() {
    if (user) {
      setCountPayouts(user.PayableReferrals);
    }
  }

  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      } finally {
        document.body.removeChild(textarea);
        toast.success(
          "Referral link copied to clipboard! You can now paste it wherever you need it."
        );
      }
    }
  }

  function setPayout(val, maxVal) {
    if (val <= maxVal) {
      setCountPayouts(val);
    } else {
      setCountPayouts(maxVal);
    }
  }

  useEffect(() => {
    fetchData();
  }, [kratosUser, payoutResp]);

  useEffect(() => {
    if (isEdit) {
      isSettings(true);
    } else {
      isSettings(false);
    }
  }, [isEdit]);

  return (
    <>
      {isEdit ? (
        <EditForm>
          <GroupContainerContent>
            <Heading>Account settings</Heading>
            <AccountFlow fullForm />
            <SpecialText onClick={() => setIsEdit(false)}>
              <FaArrowLeft />
              Back to account
            </SpecialText>
          </GroupContainerContent>
        </EditForm>
      ) : (
        <>
          {user.Internal.Email ? (
            <Container>
              <SummaryContainer>
                <AlignContainer>
                  <Title>
                    Start chatting with a <Colored>God</Colored>
                  </Title>
                  <GroupContent>
                    <GroupContainerLeft>
                      <GroupContainerContent>
                        <GroupContainerGrouping>
                          <ProfileHeader>
                            <Subheading>Your account details</Subheading>
                            {!isMobile ? (
                              <NoReferralsContainer>
                                <p>
                                  <span style={{ color: "#01F967" }}>
                                    {!user.Internal.StripeID
                                      ? `Free version - 30 days`
                                      : "Premium subscription"}
                                  </span>
                                </p>
                              </NoReferralsContainer>
                            ) : null}
                          </ProfileHeader>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <AccountInfoRow>
                                <MdEmail size={24} />
                                <p>{user.Internal.Email || "Not added"}</p>
                              </AccountInfoRow>
                              <AccountInfoRow>
                                <AiFillPhone size={24} />
                                <p>{user.Internal.Number || "Not added"}</p>
                              </AccountInfoRow>
                              <AccountInfoRow>
                                <IoLogoWhatsapp size={24} />
                                <p>
                                  {user.Internal.WhatsAppName
                                    ? user.Internal.WhatsAppName
                                    : "Not connected"}
                                </p>
                              </AccountInfoRow>
                              <AccountInfoRow>
                                <BsTelegram size={24} />
                                <p>
                                  {user.Internal.Telegram.Username
                                    ? user.Internal.Telegram.Username
                                    : "Not connected"}
                                </p>
                              </AccountInfoRow>
                            </div>
                            <ButtonsContainer>
                              {!isMobile ? (
                                <>
                                  <Button
                                    onClick={() => setIsEdit(true)}
                                    style={{ whiteSpace: "nowrap" }}
                                    icon={<FiEdit3 />}
                                  >
                                    Edit Account
                                  </Button>
                                  {user.Internal.StripeID ? (
                                    <a
                                      href={`https://billing.chatgod.ai/p/login/fZe6pMcRJ7h1e52288?prefilled_email=${user.Internal.Email}`}
                                      target="_blank"
                                      rel="noreferrer noopener"
                                    >
                                      <Button type="button">
                                        Billing details
                                      </Button>
                                    </a>
                                  ) : null}
                                </>
                              ) : null}
                            </ButtonsContainer>
                          </div>
                        </GroupContainerGrouping>
                        {isMobile ? (
                          <>
                            <NoReferralsContainer>
                              <p>
                                <span style={{ color: "#01F967" }}>
                                  {!user.Internal.StripeID
                                    ? `Free version - 30 days`
                                    : "Premium subscription"}
                                </span>
                              </p>
                            </NoReferralsContainer>
                            <Button
                              onClick={() => setIsEdit(true)}
                              icon={<FiEdit3 />}
                            >
                              Edit Account
                            </Button>
                            {user.Internal.StripeID ? (
                              <a
                                href={`https://billing.chatgod.ai/p/login/fZe6pMcRJ7h1e52288?prefilled_email=${user.Internal.Email}`}
                              >
                                <Button type="button">Billing details</Button>
                              </a>
                            ) : null}
                          </>
                        ) : null}
                      </GroupContainerContent>
                      {!user.Internal.StripeID ? (
                        <GroupContainerContent>
                          <div style={{ display: "flex" }}>
                            <div>
                              <BoldTextColored>
                                Upgrade for{" "}
                                <Colored>
                                  unlimited conversation with ChatGod.ai{" "}
                                </Colored>
                              </BoldTextColored>
                              <span
                                style={{
                                  fontSize: 18,
                                  whiteSpace: "nowrap",
                                  fontWeight: "normal",
                                }}
                              >
                                from{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  $3.99 a month
                                </span>
                              </span>
                            </div>
                            <a
                              href="https://chatgod.ai/pricing/"
                              style={{ width: isMobile ? "100%" : "auto" }}
                            >
                              <Button type="button" gradient textColor="#fff">
                                Upgrade
                              </Button>
                            </a>
                          </div>
                        </GroupContainerContent>
                      ) : null}
                      <GroupContainerContent>
                        <GroupSocials>
                          <Subheading>Answer your questions</Subheading>
                          <GroupLinks>
                            <ContainerLink
                              href={"http://t.me/OfficialChatGodAIBot"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Telegram
                              <img src={telegram} alt="telegram" />
                            </ContainerLink>
                            <ContainerLink
                              href={"http://wa.me/+17192594037?text=/start"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              WhatsApp
                              <img src={whatsapp} alt="whatsapp" />
                            </ContainerLink>
                          </GroupLinks>
                        </GroupSocials>
                        <TextContainer>
                          <Text>
                            Need help with something? DM us on telegram{" "}
                            <Link
                              href="https://t.me/ChatGodAI"
                              rel="noreferrer noopener"
                              target="_blank"
                            >
                              <BoldText>@ChatGodAI</BoldText>
                            </Link>
                          </Text>
                        </TextContainer>
                      </GroupContainerContent>
                    </GroupContainerLeft>
                    <GroupContainerRight>
                      <img src={god} alt="god" />
                    </GroupContainerRight>
                  </GroupContent>
                </AlignContainer>
                <ReferralsContainer>
                  <AlignContainer>
                    <ProfileHeader>
                      <Heading>Referrals</Heading>{" "}
                      {!isMobile ? (
                        <Button onClick={affiliateLoginOrCreate}>
                          {user.StripeAffiliateAccount
                            ? "Stripe Affiliate Login"
                            : "Create Stripe Affiliate Account"}
                        </Button>
                      ) : null}
                    </ProfileHeader>
                    {user.Internal.ReferralCode === "" ? (
                      ""
                    ) : (
                      <div style={{ marginBottom: 12 }}>
                        <BoldText>Referral Link: </BoldText>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            copyToClipboard(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/register?ref=" +
                                user.Internal.ReferralCode
                            )
                          }
                        >
                          {window.location.protocol +
                            "//" +
                            window.location.host +
                            "/register?ref=" +
                            user.Internal.ReferralCode}
                        </span>
                      </div>
                    )}
                    <List>
                      <li>
                        <span>
                          Refer people to ChatGod with your personal referral
                          link, and earn $3 once your referral signs up for a
                          ChatGod subscription.
                        </span>
                      </li>
                      <li>
                        <span>
                          Referrals can be paid out through Stripe or through
                          crypto as USDT (BEP20).
                        </span>
                      </li>
                      <li>
                        <span>
                          For USDT crypto there is no payout Minimum.
                          <br />
                          For Stripe the minimum payout quantity is 3 referrals.
                        </span>
                      </li>
                    </List>
                    <div style={{ marginBottom: "4rem" }} />
                    <>
                      {user.Referrals.length > 0 ? (
                        <>
                          <FormContent>
                            <PayoutForm onSubmit={(e) => handlePayout(e)}>
                              <PayoutGroup>
                                <ModifiedInput>
                                  <CountInput
                                    type="number"
                                    max={user.Referrals.length}
                                    min={0}
                                    placeholder="0"
                                    value={countPayouts}
                                    onChange={(e) =>
                                      setPayout(
                                        e.target.value,
                                        user.PayableReferrals
                                      )
                                    }
                                    required
                                  />
                                  <Separator />
                                  <MaxContainer onClick={maxPayout}>
                                    <BoldText>Max</BoldText>
                                  </MaxContainer>
                                </ModifiedInput>
                                <Select
                                  onChange={(e) =>
                                    setPayoutMethod(e.target.value)
                                  }
                                  value={payoutMethod}
                                  required
                                >
                                  <option value="stripe">Stripe</option>
                                  <option value="crypto">Crypto</option>
                                </Select>
                              </PayoutGroup>
                              {payoutMethod === "crypto" ? (
                                <WalletInput
                                  type="text"
                                  placeholder="0x000"
                                  value={walletAddress}
                                  onChange={(e) =>
                                    setWalletAddress(e.target.value)
                                  }
                                  required
                                />
                              ) : null}
                              <PayoutButton
                                type="submit"
                                disabled={isPayoutDisabled()}
                                isDisabled={isPayoutDisabled()}
                              >
                                <FaMoneyBillWave />
                                Payout
                              </PayoutButton>
                            </PayoutForm>
                          </FormContent>
                          <ModalContainer>
                            <FaInfoCircle
                              onMouseEnter={() => setIsInfo(true)}
                            />
                            {isInfo ? (
                              <ModalContent
                                onMouseLeave={() => setIsInfo(false)}
                              >
                                <Text>
                                  Below you see a list of all the users that
                                  have signed up using your referral code. Once
                                  users have subscribed to a paid plan, as
                                  marked next to the referrals, you will earn
                                  the referral reward.
                                </Text>
                              </ModalContent>
                            ) : null}
                          </ModalContainer>
                          <InfoPanel>
                            <p>
                              Stripe Affiliate Account:{" "}
                              {user && user.StripeAffiliateAccount ? (
                                <FcCheckmark />
                              ) : (
                                <IoMdClose color="red" />
                              )}
                            </p>
                            <p>
                              Payable Referrals:{" "}
                              {user ? user.PayableReferrals : 0}
                            </p>
                          </InfoPanel>
                          <Table>
                            <tbody>
                              <TableRowHeading>
                                <TableHeading>Email</TableHeading>
                                <TableHeading>Subscribed</TableHeading>
                              </TableRowHeading>
                              {user.Referrals.slice(0, itemLimit).map(
                                (referral, i) => (
                                  <TableRow key={i}>
                                    <TableData>
                                      {referral.InviteeEmail}
                                    </TableData>
                                    <TableData>
                                      {referral.IsInviteeSubscribed ? (
                                        <FcCheckmark />
                                      ) : (
                                        <IoMdClose color="red" />
                                      )}
                                    </TableData>
                                  </TableRow>
                                )
                              )}
                            </tbody>
                          </Table>
                          <Pagination>
                            {user.Referrals.length > itemLimit ? (
                              <div
                                onClick={() =>
                                  setItemLimit((prevState) => prevState + 5)
                                }
                              >
                                Show more
                              </div>
                            ) : null}
                          </Pagination>
                        </>
                      ) : (
                        <NoReferralsContainer>
                          <p>
                            You have not made any referrals yet.{" "}
                            <span style={{ color: "#01F967" }}>
                              Start referring people to ChatGod and earn $3 for
                              every referral!
                            </span>
                          </p>
                        </NoReferralsContainer>
                      )}
                    </>
                    {isMobile ? (
                      <Button onClick={affiliateLoginOrCreate}>
                        {user.StripeAffiliateAccount
                          ? "Stripe Affiliate Login"
                          : "Create Stripe Affiliate Account"}
                      </Button>
                    ) : null}
                  </AlignContainer>
                </ReferralsContainer>
              </SummaryContainer>
            </Container>
          ) : (
            <LoaderContainer>
              <RingLoader color="#696969" loading={true} size={150} />
              <p>Loading..</p>
            </LoaderContainer>
          )}
        </>
      )}
    </>
  );
};

export default AccountPage;
