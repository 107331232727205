import axios from "axios";

var stripeApi = process.env.REACT_APP_STRIPE_API_URL;

if (stripeApi === undefined) {
  stripeApi = process.env.REACT_APP_BACKEND_DOMAIN;
};

// Fetch transaction object
export async function fetchTransactionDetail(session_id) {
  console.log(`Stripe API: ${stripeApi}`);

  const { data } = await axios.post(
    `${stripeApi}/api/session`,
    {
      sessionID: session_id,
    }
  );
  console.log("works");
  return data;
}

// Fetch user data with Stripe customer ID
export async function fetchUserData(user_id) {
  const response = await axios.post(
    `${stripeApi}/api/user`,
    {
      userID: user_id,
    }
  );

  return response;
}

// Cancel user's subscription
export async function cancelSubscription(stripe_id) {
  const response = await axios.post(
    `${stripeApi}/api/cancel`,
    {
      userID: stripe_id,
    }
  );

  return response;
}
