import { Configuration, FrontendApi } from "@ory/kratos-client";

export const kratosClient = new FrontendApi(
  new Configuration({
    basePath: `${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_KRATOS_PATH}`,
    baseOptions: {
      withCredentials: true,
    },
  })
);
