import { createGlobalStyle } from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";
import { PRIMARY_FONT, RICH_BLACK, WHITE } from "../utils/constants";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  
  html {
    font-size: 62.5%;

    @media ${MediaQuery.isTablet} {
      font-size: 60.5%;
    }

    @media ${MediaQuery.isMobile} {
        font-size: 56.5%;
    } 
  }


  body {
   background-color: ${RICH_BLACK};
   font-size: 1.8rem;
   font-family: ${PRIMARY_FONT};
   color: ${WHITE};
   

     .Toastify {
      z-index: 100000000;
      position: relative;
    }


    .Toastify__toast-container {
      width: fit-content !important;
    }
  }
`;
