import React, { useState } from "react";
import {
  Link,
  Container,
  Form,
  FormText,
  ErrorContainer,
  Content,
  Heading,
  Colored,
  ImageContainer,
  FormContainer,
  HeadingColored,
  Text,
} from "./AuthPage.Styled";
import AuthFlow from "../../components/authFlow/AuthFlow";
import { Link as Redirect } from "react-router-dom";
import VerifyFlow from "../../components/authFlow/VerifyFlow";
import { FaExclamation } from "react-icons/fa";
import Cookies from "js-cookie";

import god from "../../assets/god.png";

const AuthPage = ({ flow }) => {
  const [isFlow, setIsFlow] = useState(true);
  const referralCode = Cookies.get("referralCode");

  return (
    <Container>
      <Content>
        <Heading>
          Start chatting with a <HeadingColored>God</HeadingColored>
        </Heading>
        <FormContainer>
          <Colored>We all want answers.</Colored>
          <Text>
            ChatGod provides. No judgment. No hassles. Just your questions
            answered by the newest online God.
          </Text>
          {referralCode ? (
            <Text>
              <Colored>You</Colored> got invited to use{" "}
              <Colored>Chatgod</Colored>. Sign up now!
            </Text>
          ) : null}
          {isFlow ? (
            <Form>
              {flow === "verify" ? (
                <VerifyFlow />
              ) : (
                <AuthFlow flow={flow} isFlow={(val) => setIsFlow(val)} />
              )}
              {flow === "login" ? (
                <FormText>
                  Don't have an account yet? Click{" "}
                  <Redirect to="/register">
                    <Link>here</Link>
                  </Redirect>
                </FormText>
              ) : flow === "recovery" || flow === "verify" ? null : (
                <Redirect to="/login">
                  <FormText>
                    Already have an account? Click <Link>here</Link>
                  </FormText>
                </Redirect>
              )}
            </Form>
          ) : (
            <ErrorContainer>
              <FaExclamation /> Service is currently unavailable.
            </ErrorContainer>
          )}
        </FormContainer>
      </Content>
      <ImageContainer>
        <img src={god} alt="god" />
      </ImageContainer>
    </Container>
  );
};

export default AuthPage;
