import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthPage from "./pages/authPage/AuthPage";
import SharePage from "./pages/sharePage/SharePage";
import { GlobalStyles } from "./styles/GlobalStyles";
import CheckoutPage from "./pages/checkoutPage/CheckoutPage";
import SnackBar from "./components/snackBar/SnackBar";
import "react-toastify/dist/ReactToastify.css";
import PromptPage from "./pages/promptPage/PromptPage";
import AccountPage from "./pages/accountPage/AccountPage";
import SettingsPage from "./pages/settingsPage/SettingsPage";
import Cookies from "js-cookie";
import Layout from "./components/layout/Layout";
import { hotjar } from "react-hotjar";
import { useEffect, useState } from "react";

hotjar.initialize(3427179, 6);

function App() {
  const [isSettings, setIsSettings] = useState(false);
  // check urlParam for referral code
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("ref")) {
    const referralCode = urlParams.get("ref");
    Cookies.set("referralCode", referralCode);
  }

  return (
    <main>
      <BrowserRouter>
        <GlobalStyles />
        <SnackBar />
        <Layout isSettings={isSettings}>
          <Routes>
            <Route path="/login" element={<AuthPage flow="login" />} />
            <Route path="/register" element={<AuthPage flow="register" />} />
            <Route path="/recovery" element={<AuthPage flow="recovery" />} />
            <Route path="/verify" element={<AuthPage flow="verify" />} />
            <Route
              path="/account"
              element={<AccountPage isSettings={setIsSettings} />}
            />
            {/* <Route path="/share" element={<SharePage />} /> */}
            <Route path="/after_checkout" element={<CheckoutPage />} />
            {/* <Route path="/prompts" element={<PromptPage />} /> */}
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="*" element={<Navigate to="/account" replace />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </main>
  );
}

export default App;
