import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  BLACK,
  GOLD,
  PRIMARY_FONT,
  WHITE,
  RED,
  GRAY,
  FOGRA_BLACK,
  BLUE,
} from "../../utils/constants";
import referralsBackground from "../../assets/referrals-bg.png";
import bulletPoint from "../../assets/bullet-point.svg";
import billingBackground from "../../assets/billing-bg.jpg";

export const Container = styled.div``;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin-bottom: 1rem;

  @media ${MediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  margin: 2rem 0;
  display: flex;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const ContentHeading = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 6.4rem;
  text-align: center;
  color: ${WHITE};
  margin: 6rem 0 4rem;
`;

export const ListBullet = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: 6.4rem;
  color: ${WHITE};
  margin-right: 2rem;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
`;

export const ListItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
`;

export const Colored = styled.span`
  background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
  line-height: 4rem;
`;

export const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${WHITE};
  padding: 1rem 2rem;
  margin-top: 1rem;
`;
export const IconContainer = styled.figure`
  margin-left: 1rem;
  color: ${GOLD};
  cursor: pointer;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 4rem;
  color: #313131;
`;

export const Terms = styled.p`
  text-align: center;
`;

export const AccountForm = styled.form`
  button {
    margin-top: 4rem;
  }
`;

export const Heading = styled.h2`
  font-size: 4.8rem;
  background: linear-gradient(90deg, #ffffff -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 1rem;
`;

export const Subheading = styled.h3`
  font-size: 2.8rem;
  background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 9.6rem;
  line-height: 10rem;

  @media ${MediaQuery.isTablet} {
    font-size: 7rem;
    line-height: 8rem;
  }

  @media ${MediaQuery.isMobile} {
    font-size: 4.8rem;
    line-height: 5rem;
  }
`;

export const SummaryContainer = styled.div`
  margin: 4rem 0 4rem;

  a {
    text-decoration: none;
    color: ${WHITE};
  }

  p {
    line-height: 3rem;
  }

  button {
    margin: 3rem 0 1rem;
  }

  @media ${MediaQuery.isMobile} {
    margin: 0;
  }
`;

export const SummaryGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryHeading = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.5rem;
  color: ${WHITE};
  margin: 3rem 0 2rem;
`;

export const CancelText = styled.p`
  font-size: 1.5rem;
  color: ${RED};
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: grid;
  margin-top: 4rem;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
`;

export const ModalHeading = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export const ModalText = styled.p`
  margin-bottom: 2rem;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const SpecialText = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export const Table = styled.table`
  display: table;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  border-spacing: 0;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  overflow: hidden;
`;

export const TableData = styled.td`
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid ${BLUE};
  padding: 0.4rem 0;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const TableHeading = styled.th``;

export const TableRow = styled.tr`
  width: 100%;
`;

export const TableRowHeading = styled.tr`
  background: ${BLUE};
`;

export const PayoutForm = styled.form`
  height: 4rem;
  display: flex;
  margin: 0 0 0.5rem;
  width: 100%;

  @media ${MediaQuery.isTablet} {
    flex-direction: column;
    margin: 4rem 0;
    height: auto;
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    margin: 2rem 0;
    height: auto;
  }
`;

export const Select = styled.select`
  height: 100%;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border: 2px solid rgb(44, 45, 46);
  color: ${BLACK};
  height: 100%;
  width: fit-content;
  border-radius: 1rem;
  font-size: 1.8rem;

  @media ${MediaQuery.isTablet} {
    width: 100%;
    margin: 1rem 0;
  }

  @media ${MediaQuery.isMobile} {
    width: 100%;
    margin: 1rem 0;
    font-size: unset;
  }
`;

export const CountInput = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  text-align: center;
  height: 100%;
  font-size: 1.8rem;
  -moz-appearance: textfield;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  max-width: 5rem;

  &:focus {
    outline: none;
  }

  @media ${MediaQuery.isMobile} {
    font-size: unset;
  }
`;

export const PayoutButton = styled.button`
  height: 100%;
  margin: 0 0.5rem !important;
  padding: 0 2rem;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
  background: ${(props) =>
    !props.isDisabled
      ? "linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%)"
      : GRAY};
  color: ${BLACK};
  border: none;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.6rem;
  }

  @media ${MediaQuery.isTablet} {
    width: 100%;
    margin: 1rem 0 !important;
    padding: 1rem 2rem;
  }

  @media ${MediaQuery.isMobile} {
    width: 100%;
    margin: 1rem 0 !important;
    padding: 1rem 2rem;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  div {
    min-width: 5rem;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const WalletInput = styled.input`
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid rgb(44, 45, 46);
  color: ${BLACK};
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
  width: 100%;
  height: 100%;
  border-radius: 1rem;

  @media ${MediaQuery.isTablet} {
    margin: 1rem 0;
    padding: 0.5rem 2rem;
  }

  @media ${MediaQuery.isMobile} {
    margin: 1rem 0;
    padding: 0.5rem 2rem;
  }
`;

export const PayoutGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media ${MediaQuery.isTablet} {
    width: 100%;
  }

  @media ${MediaQuery.isMobile} {
    width: 100%;
  }
`;

export const Separator = styled.div`
  height: 70%;
  width: 0.1rem;
  background-color: ${BLACK};
`;

export const ModifiedInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${WHITE};
  margin-right: 1rem;
  border: 2px solid rgb(44, 45, 46);
  color: ${BLACK};
  font-style: normal;
  font-weight: 500;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;

export const MaxContainer = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const InfoPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  width: 100%;

  svg {
    vertical-align: middle;
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  /* margin: 4rem 0 0.4rem; */
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${BLUE};
  border-radius: 2rem;
  color: ${WHITE};
  padding: 2rem;
  width: 100%;
  min-width: 56rem;

  @media ${MediaQuery.isMobile} {
    min-width: unset;
  }
`;

export const FormContent = styled.div`
  width: fit-content;

  @media ${MediaQuery.isTablet} {
    margin: 0 auto;
  }

  @media ${MediaQuery.isMobile} {
    width: 100%;
  }
`;

export const BoldText = styled.span`
  color: #67a5ff;
  font-weight: bold;
`;

export const GroupContainerContent = styled.section`
  background-color: #121212;
  box-shadow: 1px 1px 60px 1px rgba(61, 61, 61, 0.85);
  border-radius: 3rem;
  padding: 2rem 4rem;
  margin: 2rem 0 1rem;

  @media ${MediaQuery.isMobile} {
    padding: 2rem;
  }
`;

export const ProfileHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: fit-content;
    margin: 0 0 0 1rem;
  }

  @media ${MediaQuery.isMobile} {
    button {
      width: 100%;
      margin: 2rem 0 0;
    }
  }
`;

export const AlignContainer = styled.div`
  max-width: 130rem;
  padding: 0 4rem;
  margin: 0 auto;

  @media ${MediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }
`;

export const GroupContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -8rem;
  position: relative;
  z-index: 5;

  @media ${MediaQuery.isTablet} {
    margin-right: 0;
  }

  @media ${MediaQuery.isMobile} {
    margin-right: 0;
  }
`;

export const GroupContainerRight = styled.figure`
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 2;

  img {
    width: 100%;
  }
`;

export const ContainerLink = styled(Link)`
  background: ${WHITE};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
  border-radius: 8rem;
  padding: 1rem 2rem;
  color: ${BLACK} !important;
  font-size: 1.6rem;

  img {
    margin-left: 1rem;
  }

  @media ${MediaQuery.isMobile} {
    margin: 1rem;
  }
`;

export const GroupSocials = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: fit-content;

  button {
    margin: 0;
    margin-top: 1rem;
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 3rem;
  }
`;

export const ReferralsContainer = styled.div`
  background-image: url(${referralsBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 8rem 0;
`;

export const GroupContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${MediaQuery.isTablet} {
    display: flex;
    flex-direction: column;
  }

  @media ${MediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const List = styled.ul`
  list-style-image: url(${bulletPoint});

  span {
    position: relative;
    left: 1rem;
  }

  @media ${MediaQuery.isMobile} {
    margin-left: 2rem;
    font-size: 1.6rem;
  }
`;

export const NoReferralsContainer = styled.div`
  border-radius: 3rem;
  padding: 0.2rem 2rem;
  width: fit-content;
  font-size: 1.2rem;
  background-color: #0a3a2d;
  margin: 2rem 0;
`;

export const BillingContainer = styled.div`
  background-image: url(${billingBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 8rem 0;
`;

export const EditForm = styled.div`
  max-width: 60rem;
  padding: 0 2rem;
  margin: 0 auto;
`;

export const SubscriptionStatus = styled.p`
  font-size: 1.8rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const GroupContainerGrouping = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupLinks = styled.div`
  display: flex;
  margin: 2.6rem 0 0;
`;

export const AccountInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  min-height: 3.5rem;

  svg {
    margin-right: 2rem;
    height: 3rem;
    color: #e4e4e4;
  }
`;

export const BoldTextColored = styled.p`
  font-size: 2.4rem;
  font-weight: bold;
  margin-right: 2rem;
`;
