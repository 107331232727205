import { ToastContainer } from "react-toastify";
import { LIGHTER_BLACK } from "../../utils/constants";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import React from "react";
import { useLocation } from "react-router-dom";

const SnackBar = () => {
  const location = useLocation();

  useEffect(() => {
    hotjar.stateChange(location.pathname);
  }, [location.pathname]);

  return (
    <ToastContainer
      limit="{1}"
      theme="dark"
      toastOptions={{
        duration: 7000,
        style: {
          background: LIGHTER_BLACK,
          color: "#fff",
        },

        warn: {
          duration: 12000,
        },

        // Default options for specific types
        success: {
          duration: 4000,
        },
      }}
    />
  );
};

export default SnackBar;
