import styled from "styled-components";
import { GOLD, GRAY, WHITE } from "../../utils/constants";
import { MediaQuery } from "../../hooks/useDeviceType";

export const Container = styled.section``;

export const Link = styled.span`
  color: ${GOLD};
  cursor: pointer;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 4rem;
  color: ${GRAY};
  margin: 1rem 0 3rem;
  max-width: 90rem;
  text-align: center;

  @media ${MediaQuery.isMobile} {
    font-size: 2rem;
    max-width: 90%;
    margin: 0 auto;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto;

  a {
    color: ${WHITE};
    text-decoration: none;
  }
`;

export const FormText = styled.p`
  margin: 2rem 0 0;
`;

export const ErrorContainer = styled.div`
  padding: 1rem 2rem;
  background-color: #9e0000;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  svg {
    margin-right: 3rem;
    height: 3rem;
    width: 3rem;
  }
`;
