import styled from "styled-components";

export const Container = styled.div``;

export const Heading = styled.h1`
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Subheading = styled.h2`
  margin: 3rem 0 0.4rem;
  font-size: 2.4rem;
`;
