import styled from "styled-components";
import {
  BLACK,
  COOL_GREY50,
  DARK_BLUE,
  GUPPLE_GREEN,
  INDEPENDENCE,
  LIGHTER_BLACK,
  RED,
  WHITE,
} from "../../utils/constants";

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  a {
    cursor: pointer;
    text-align: right;
    width: 100%;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${LIGHTER_BLACK};
    -webkit-text-fill-color: ${BLACK};
    -webkit-box-shadow: 0 0 0px 1000px ${WHITE} inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Input = styled.input`
  padding: 1rem 2rem;
  border: 2px solid rgb(44, 45, 46);
  background-color: ${WHITE};
  color: ${BLACK};
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
  width: 100%;
  max-width: 46rem;
  border-radius: 1rem;
`;

export const OIDCButton = styled.button`
  background-color: ${WHITE};
  border-radius: 5.6rem;
  width: 100%;
  border: none;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.6rem;
  color: ${BLACK};

  svg {
    margin-right: 1rem;
    height: 3rem;
    width: 3rem;
  }
`;

export const ForgotPwdContainer = styled.div`
  width: 100%;
  font-size: 1.6rem;

  a {
    color: ${GUPPLE_GREEN};
  }
`;

export const WarnContainer = styled.div`
  padding: 1rem 2rem;
  background-color: #ba683b;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 3rem;
    height: 3rem;
    width: 3rem;
  }
`;

export const SuccessContainer = styled.div`
  padding: 1rem 2rem;
  background-color: #1eb004;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 3rem;
    height: 3rem;
    width: 3rem;
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    margin: 0 1rem;
    color: ${INDEPENDENCE};
    font-size: 1.2rem;
  }
`;

export const Line = styled.div`
  height: 0.2rem;
  width: 100%;
  background-color: ${COOL_GREY50};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 3rem 0 0;
  position: relative;

  p {
    position: absolute;
    top: -2.4rem;
    color: ${RED};
  }
`;
