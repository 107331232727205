import styled from "styled-components";
import { GRAY } from "../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 2rem;
    text-align: center;
    color: ${GRAY};
  }

  button {
    margin-top: 4rem;
  }
`;
