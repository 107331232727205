import React, { useEffect, useState } from "react";
import {
  Container,
  Heading,
  Text,
  Line,
  GroupContainerContent,
  Colored,
  InfoRow,
} from "./CheckoutPage.Styled";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchTransactionDetail } from "../../api/utils/stripe";
import Button from "../../components/button/Button";
import PuffLoader from "react-spinners/PuffLoader";
import { LoaderContainer } from "../accountPage/AccountPage.Styled";
import { kratosClient } from "../../api/kratos";
import { AiOutlineCheck } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { GUPPLE_GREEN } from "../../utils/constants";
import { toast } from "react-toastify";
import copyToClipboard from "../../utils/copy";

import { IoLogoWhatsapp } from "react-icons/io";
import { BsTelegram, BsShieldFill } from "react-icons/bs";

const CheckoutPage = () => {
  const [isError, setIsError] = useState(false);
  const [seconds, setSeconds] = useState(5);
  const [isKratosSession, setIsKratosSession] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [checkoutSession, setCheckoutSession] = useState({});
  const [registrationUrl, setRegistrationUrl] = useState("/register");

  const queryParams = new URLSearchParams(location.search);
  const sessionID = queryParams.get("session_id");

  async function fetchData() {
    if (sessionID) {
      try {
        const checkoutData = await fetchTransactionDetail(sessionID);
        setCheckoutSession(checkoutData);

        let email = encodeURIComponent(checkoutData.customer_details?.email);
        let phone = encodeURIComponent(checkoutData.customer_details?.phone);
        setRegistrationUrl(`/register?email=${email}&phone=${phone}`);

        const tier = checkoutData.tier || "tier";
        const amount = checkoutData.amount || 0;

        // Send data to Google Analytics
        window.gtag("event", "purchase", {
          event_category: "Subscription",
          event_label: tier, // Send the tier info here
          value: amount, // Send the amount info here
        });

        console.log("Checkoutdata:", checkoutData);
      } catch (err) {
        console.log(err);
        setIsError(true);
        // setTimeout(() => navigate("/"), 5000);
        setInterval(() => setSeconds((prevState) => prevState - 1), 1000);
      }
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    kratosClient
      .toSession()
      .then(() => {
        setIsKratosSession(true);
      })
      .catch(() => {
        setIsKratosSession(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [sessionID]);

  // TODO: session status can also be 'open' or 'expired'
  // If one of the above, check if url property is set, if so display error message with
  // smth like "Checkout failed. Please click the button to check your checkout"
  // And show button if url, present, otherwise only error message
  return (
    <Container>
      {checkoutSession?.status === "complete" ? (
        <GroupContainerContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AiOutlineCheck
              color={GUPPLE_GREEN.hex()}
              style={{ marginRight: "1rem" }}
            />
            <Colored style={{ fontSize: "1.4em", fontWeight: "300" }}>
              Your payment was successful!
            </Colored>
          </div>
          <Text>Talking to God just got a whole lot easier.</Text>
          <Text>Spread the good word.</Text>
          <Line />
          <Heading>Order Summary</Heading>
          <InfoRow>
            <FiEdit3 size="1.4em" />
            <Text> {checkoutSession.customer_details?.name}</Text>
          </InfoRow>
          <InfoRow>
            <MdEmail size="1.4em" />
            <Text> {checkoutSession.customer_details?.email}</Text>
          </InfoRow>
          <InfoRow>
            <AiFillPhone size="1.4em" />
            <Text> {checkoutSession.customer_details?.phone}</Text>
          </InfoRow>
          {isKratosSession ? (
            <Button onClick={"/account"}>Account</Button>
          ) : (
            <>
              <Heading style={{ marginTop: "2rem" }}>Account</Heading>
              <p>Create an account to manage your subscription.</p>
              <p
                style={{
                  fontSize: "0.9em",
                  color: "#bfbfbf",
                  marginTop: "2rem",
                }}
              >
                Note: Register the account with the WhatsApp / Telegram phone number you want to acccess ChatGod with.
              </p>
              <Button
                onClick={() => window.open(registrationUrl, "_blank")}
                style={{ marginTop: "2rem" }}
              >
                Register
              </Button>
            </>
          )}
          <Heading style={{ marginTop: "2rem" }}>
            Get Started With ChatGod
          </Heading>
          {checkoutSession?.is_business ? (
            <>
              <p>
                To get started with ChatGod Business, please add the number
                below to your group chat.
              </p>
              <p
                style={{
                  fontSize: "0.9em",
                  color: "#bfbfbf",
                  marginTop: "2rem",
                }}
              >
                Note: The WhatsApp number for business use differs from the main
                WhatsApp number.
              </p>
            </>
          ) : (
            <p>
              To get started, please add ChatGod to your contacts and send a
              message to the number below.
            </p>
          )}
          {checkoutSession?.product_links?.map((link) => {
            return (
              <Button
                gradient={false}
                key={link.url}
                onClick={async () => {
                  switch (link.action) {
                    case "copy":
                      let success = await copyToClipboard(link.url);
                      if (success === true) {
                        toast.success("Phone number copied to clipboard");
                      }
                      break;
                    case "navigate":
                      window.open(link.url, "_blank");
                      break;
                  }
                }}
              >
                <InfoRow>
                  {link.type === "WhatsApp" ? (
                    <IoLogoWhatsapp
                      size={"8rem"}
                      style={{
                        marginRight: "0",
                        padding: "0",
                      }}
                      color="#25D366"
                    />
                  ) : (
                    <BsTelegram
                      size={"8rem"}
                      style={{ marginRight: "0" }}
                      color="#0088cc"
                    />
                  )}

                  <p style={{ color: "#161616" }}>{link.label}</p>
                </InfoRow>
              </Button>
            );
          })}
        </GroupContainerContent>
      ) : (
        <LoaderContainer>
          <PuffLoader color="#696969" loading={true} size={150} />
          {isError ? (
            <>
              <p>The session ID is invalid..</p>
              <p>You will be redirected in {seconds}.</p>
            </>
          ) : null}
        </LoaderContainer>
      )}
    </Container>
  );
};

export default CheckoutPage;
