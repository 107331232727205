import color from "color";

// breakpoints
export const TABLET_BREAKPOINT = 688;
export const SMALL_MOBILE_BREAKPOINT = 375;
export const DESKTOP_BREAKPOINT = 1100;

// colors
export const DARK_BLUE = color("#004cbf");
export const RICH_BLACK = color("#04080F");
export const FOGRA_BLACK = color("#020407");
export const COOL_GREY = color("#8896AB");
export const COOL_GREY50 = color("#8896AB80");
export const GUPPLE_GREEN = color("#01F967");
export const INDEPENDENCE = color("#464F5D");

export const LIGHT_BLACK = color("#141414");
export const LIGHTER_BLACK = color("#1B1C1E");
export const WHITE = color("#ffffff");
export const BLUE = color("#756bf4");
export const OPACITY_BLACK = color("#00000080");
export const BLACK = color("#000000");
export const GRAY = color("#8e8a8b");
export const LIGHT_WHITE = color("#e5e5e5");
export const RED = color("#cf0000");
export const GOLD = color("#BD9A66");
export const LIGHT_GOLD = color("#F8E2A9");

// fonts
export const PRIMARY_FONT = "Poppins";

// keys
export const AUTH_TOKEN_KEY = "authToken";
export const AUTH_REFRESH_KEY = "authRefresh";

// other
export const GOD_HEIGHT = "60rem";
export const HEADER_HEIGHT = "12rem";
