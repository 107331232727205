import styled from "styled-components";
import { GUPPLE_GREEN } from "../../utils/constants";
import { MediaQuery } from "../../hooks/useDeviceType";
import { GOD_HEIGHT } from "../../utils/constants";

export const Container = styled.section`
  position: relative;
  min-height: inherit;
  width: 100%;

  @media ${MediaQuery.isTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Link = styled.span`
  color: ${GUPPLE_GREEN};
  cursor: pointer;
`;

export const Colored = styled.span`
  background: linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1.4rem;
`;

export const Text = styled.p`
  margin: 1rem 0 3rem;

  @media ${MediaQuery.isMobile} {
    font-size: 2rem;
    margin: 0 auto;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

export const FormText = styled.p`
  color: #8896ab;
  font-size: 1.4rem;
`;

export const ErrorContainer = styled.div`
  padding: 1rem 2rem;
  background-color: #9e0000;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  svg {
    margin-right: 3rem;
    height: 3rem;
    width: 3rem;
  }
`;

export const ImageContainer = styled.figure`
  max-width: ${GOD_HEIGHT};
  max-height: ${GOD_HEIGHT};
  width: 100%;
  height: 100%;
  margin-top: 8rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  img {
    height: 100%;
    width: 100%;
  }

  @media ${MediaQuery.isTablet} {
    position: unset;
    transform: unset;
  }

  @media ${MediaQuery.isMobile} {
    position: unset;
    transform: unset;
  }
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 9.6rem;
  line-height: 10rem;
  max-width: 50rem;

  @media ${MediaQuery.isTablet} {
    font-size: 7rem;
    line-height: 8rem;
    margin-bottom: 4rem;
  }

  @media ${MediaQuery.isMobile} {
    font-size: 7rem;
    line-height: 8rem;
    margin-bottom: 4rem;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 12rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  max-width: 124rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;

  @media ${MediaQuery.isTablet} {
    position: unset;
    flex-direction: column;
    max-width: unset;
    transform: unset;
    align-items: center;
  }

  @media ${MediaQuery.isMobile} {
    position: unset;
    flex-direction: column;
    max-width: unset;
    transform: unset;
  }
`;

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  width: 100%;
`;

export const HeadingColored = styled(Colored)`
  font-size: 9.6rem;

  @media ${MediaQuery.isTablet} {
    font-size: 7rem;
  }

  @media ${MediaQuery.isMobile} {
    font-size: 7rem;
  }
`;
