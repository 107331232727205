import React, { useEffect, useState } from "react";
import { Input, Form, OIDCButton } from "./styles";
import Button from "../../components/button/Button";
import { LIGHTER_BLACK } from "../../utils/constants";
import { kratosClient } from "../../api/kratos";
import { useNavigate } from "react-router-dom";
import { handleMessages } from "../snacks";
import { handleFlowError } from "../errors";
import { capitalizeFirstLetter } from "../../utils/helpers";
import { FcGoogle } from "react-icons/fc";

const Inputs = ({ type, flowData, setFlowData, flow }) => {
  const [uiNodes, setUiNodes] = useState([]);

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const btn = e.nativeEvent.submitter;
    const formData = {};

    for (let i = 0; i < e.target.elements.length; i++) {
      const element = e.target.elements[i];

      // Check if we need to resend the code
      if (element.name === "code" && btn.value !== "code") {
        continue;
      }

      formData[element.name] = element.value;
    }

    const flowSubmit = {
      recovery: () =>
        kratosClient.updateRecoveryFlow({
          flow: String(flowData?.id),
          updateRecoveryFlowBody: formData,
        }),
      login: () =>
        kratosClient.updateLoginFlow({
          flow: String(flowData?.id),
          updateLoginFlowBody: formData,
        }),
      register: () =>
        kratosClient.updateRegistrationFlow({
          flow: String(flowData?.id),
          updateRegistrationFlowBody: formData,
        }),
      settings: () =>
        kratosClient.updateSettingsFlow({
          flow: String(flowData?.id),
          updateSettingsFlowBody: formData,
        }),
    }[flow]?.();

    if (!flowSubmit) {
      return;
    }

    try {
      const { data } = await flowSubmit;

      if (flow === "login" && data.session) {
        navigate("/account");
      }

      if (flow === "register" && data.identity?.state === "active") {
        navigate("/login", { state: { redirected: true } });
      }

      if (flow === "settings" && data.state === "success") {
        setTimeout(() => navigate("/account"), 3000);
      }

      handleMessages(data);
      setFlowData(data);
    } catch (err) {
      handleMessages(err.response.data);
      handleFlowError(navigate, flow, setFlowData);

      if (err.response.data.error) {
        if (
          err.response.data.error.id &&
          err.response.data.redirect_browser_to
        ) {
          window.location.replace(err.response.data.redirect_browser_to);
        }
      }
    }
  }

  const getCsrfTokenNode = (nodes) => {
    return nodes.find((node) => {
      return (
        node.attributes.name === "csrf_token" &&
        node.attributes.type === "hidden"
      );
    });
  };

  useEffect(() => {
    let modifiedNodes = flowData.ui.nodes
      .filter((node) => node.group === type && node.type === "input")
      .map((node) => {
        const { value, defaultValue, ...attributes } = node.attributes;
        const modifiedAttributes = {
          ...attributes,
          defaultValue: defaultValue ?? value,
        };

        if (attributes.type === "submit") {
          modifiedAttributes.value = value;
        }

        return {
          ...node,
          attributes: modifiedAttributes,
        };
      });

    modifiedNodes.push(getCsrfTokenNode(flowData.ui.nodes));

    setUiNodes(modifiedNodes);
  }, [flowData]);

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      {uiNodes.map((node) => (
        <div key={node.attributes.name}>
          {node.attributes.type === "submit" ? (
            node.group === "oidc" ? (
              <OIDCButton {...node.attributes}>
                <FcGoogle />
                {capitalizeFirstLetter(node.meta.label.text)}{" "}
              </OIDCButton>
            ) : (
              <Button style={{ margin: 20 }} {...node.attributes}>
                {node?.meta?.label?.text}
              </Button>
            )
          ) : (
            <Input {...node.attributes} placeholder={node?.meta?.label?.text} />
          )}
        </div>
      ))}
    </Form>
  );
};

export default Inputs;
