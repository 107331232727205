// import {
//   throwSnackError,
//   throwSnackInfo,
// } from "../components/snackBar/SnackSimple";
import { toast } from "react-toastify";

export function handleMessages(data) {
  if (!data) return;

  if (
    data.error?.reason &&
    data.error?.id !== "browser_location_change_required"
  ) {
    // throwSnackError(data.error.reason);
    toast.error(data.error.reason);
  }

  data.ui?.messages?.forEach((message) => {
    if (message.type === "error") {
      // throwSnackError(message.text);
      toast.error(message.text);
      return;
    }

    // throwSnackInfo(message.text);
    toast.info(message.text);
  });

  data.ui?.nodes.forEach((node) => {
    if (node?.messages.length > 0) {
      // throwSnackInfo(node.messages[0].text);
      toast.info(node.messages[0].text);
    }
  });
}
