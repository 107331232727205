import styled from "styled-components";
import { BLACK, GOLD, GRAY, WHITE } from "../../utils/constants";

export const Container = styled.button`
  padding: 1rem 2rem;
  min-width: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  color: ${(props) => (props.textColor ? props.textColor : BLACK)};
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.7rem;
  background: ${(props) =>
    props.isDisabled
      ? GRAY
      : props.gradient
      ? "linear-gradient(90deg, #01f967 -0.77%, #67a5ff 100%)"
      : WHITE};
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 5.6rem;
  cursor: pointer;

  svg {
    margin-left: 1rem;
  }

  /* Secondary button styles */
  ${(props) =>
    props.secondary &&
    `
      padding: 1rem 2rem;
      background-color: ${GRAY};
      border: 0.2rem solid ${GRAY};
      color: ${WHITE};

      &:hover {
        background-color: ${GOLD};
        color: ${WHITE};
        border-color: ${GOLD};
      }
    `}
`;
