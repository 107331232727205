import React, { useEffect, useState } from "react";
import { kratosClient } from "../../api/kratos";
import { handleMessages } from "../../pkg/snacks";
import { handleFlowError } from "../../pkg/errors";
import Button from "../../components/button/Button";
import { Form, Input } from "./AuthFlow.Styled";
import { VerificationFlow } from "@ory/client";
import { LIGHTER_BLACK } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getObjectHash } from "../../utils/hash";

const VerifyFlow = () => {
  const [flowData, setFlowData] = useState(VerificationFlow);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const aal = queryParams.get("aal");
  const returnTo = queryParams.get("return_to");
  const flowId = queryParams.get("flow");

  async function fetchData() {
    if (!flowData) {
      if (!flowId) {
        kratosClient
          .createBrowserVerificationFlow({
            refresh: false,
            aal: aal ? String(aal) : undefined,
            returnTo: returnTo ? String(returnTo) : undefined,
          })
          .then(({ data }) => {
            navigate(`${location.pathname}?flow=${data.id}`, { replace: true });
            setFlowData(data);
          })
          .catch(handleFlowError(navigate, "verify", setFlowData));
      } else {
        kratosClient
          .getVerificationFlow({ id: flowId })
          .then(({ data }) => {
            setFlowData(data);
          })
          .catch(handleFlowError(navigate, "verify", setFlowData));
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const btn = e.nativeEvent.submitter;
    const formData = {};

    for (let i = 0; i < e.target.elements.length; i++) {
      const element = e.target.elements[i];

      // Check if we need to resend the code
      if (element.name === "code" && btn.value !== "code") {
        continue;
      }

      formData[element.name] = element.value;
    }

    kratosClient
      .updateVerificationFlow({
        flow: String(flowData?.id),
        updateVerificationFlowBody: formData,
      })
      .then(({ data }) => {
        if (data.state === "passed_challenge") {
          navigate("/login", { state: { verified: true } });
          return;
        }

        handleMessages(data);
        setFlowData(data);
      })
      .catch((err) => {
        handleFlowError(navigate, "verify", setFlowData);
        console.log(err);
      });
  }

  function getDefaultInputs() {
    let tempDefaultInputs = [];
    let passwordAndSubmit = [];

    if (flowData) {
      const formData = flowData.ui;

      for (let i = 0; i < formData.nodes.length; i++) {
        const node = formData.nodes[i];
        const { value, ...rest } = formData.nodes[i].attributes;
        const nodeAttr = value !== "" ? { value, ...rest } : { ...rest };

        if (node.group !== "oidc") {
          if (node.attributes.type === "submit") {
            value !== "code"
              ? passwordAndSubmit.push(
                  <Button
                    style={{ marginTop: 20, backgroundColor: LIGHTER_BLACK }}
                    {...nodeAttr}
                    {...(value ? { readOnly: true } : {})}
                  >
                    {node.meta.label.text}
                  </Button>
                )
              : passwordAndSubmit.push(
                  <Button
                    style={{ marginTop: 20 }}
                    {...nodeAttr}
                    {...(value ? { readOnly: true } : {})}
                  >
                    {node.meta.label.text}
                  </Button>
                );
          } else if (node.attributes.type === "hidden") {
            tempDefaultInputs.push(<Input {...nodeAttr} />);
          } else {
            if (node.attributes.name === "code") {
              tempDefaultInputs.push(
                <Input
                  placeholder={node.meta.label.text}
                  {...nodeAttr}
                  {...(value ? { readOnly: true } : {})}
                />
              );
            } else {
              tempDefaultInputs.push(
                <Input
                  placeholder={node.meta.label.text}
                  {...nodeAttr}
                  {...(value ? { readOnly: true } : {})}
                />
              );
            }
          }
        }
      }
    }

    return tempDefaultInputs.concat(passwordAndSubmit);
  }

  useEffect(() => {
    fetchData();
  }, [flowId]);

  return (
    <>
      <h1>Verify your email address:</h1>
      {flowData ? (
        <Form onSubmit={(e) => handleSubmit(e)}>
          {getDefaultInputs().map((input, i) => (
            <div key={i + getObjectHash(flowData)}>{input}</div>
          ))}
        </Form>
      ) : null}
    </>
  );
};

export default VerifyFlow;
