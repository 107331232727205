import React, { useEffect, useState } from "react";
import { kratosClient } from "../../api/kratos";
import { handleFlowError } from "../../pkg/errors";
import { SettingsFlow } from "@ory/client";
import { useLocation, useNavigate } from "react-router-dom";
import Inputs from "../../pkg/inputs/inputs";
import { Subheading } from "../../pages/accountPage/AccountPage.Styled";

const AccountFlow = ({ fullForm, isFlow }) => {
  const [flowData, setFlowData] = useState(SettingsFlow);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const aal = queryParams.get("aal");
  const returnTo = queryParams.get("return_to");
  const flowId = queryParams.get("flow");

  async function fetchData() {
    if (!flowData) {
      if (!flowId) {
        kratosClient
          .createBrowserSettingsFlow({
            refresh: false,
            aal: aal ? String(aal) : undefined,
            returnTo: returnTo ? String(returnTo) : undefined,
          })
          .then(({ data }) => {
            navigate(`${location.pathname}?flow=${data.id}`, { replace: true });
            setFlowData(data);

            if (isFlow) {
              isFlow(data);
            }
          })
          .catch(handleFlowError(navigate, "settings", setFlowData));
      } else {
        kratosClient
          .getSettingsFlow({ id: flowId })
          .then(({ data }) => {
            setFlowData(data);

            if (isFlow) {
              isFlow(data);
            }
          })
          .catch(handleFlowError(navigate, "settings", setFlowData));
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [flowId]);

  return (
    <>
      {flowData ? (
        <>
          {fullForm ? (
            <>
              <Inputs
                type="profile"
                flowData={flowData}
                flow="settings"
                setFlowData={setFlowData}
              />
              <Inputs
                type="oidc"
                flowData={flowData}
                flow="settings"
                setFlowData={setFlowData}
              />
            </>
          ) : null}

          {fullForm ? (
            <Subheading style={{ margin: 20 }}>Change password</Subheading>
          ) : (
            <h3 style={{ margin: 20 }}>Set a new password</h3>
          )}
          <Inputs
            type="password"
            flowData={flowData}
            flow="settings"
            setFlowData={setFlowData}
          />
        </>
      ) : null}
    </>
  );
};

export default AccountFlow;
